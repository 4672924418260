.Button {
  display: flex;
  padding: 14px 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--surface-two);
  border-radius: 8px;
  color: var(--text-primary);
  @apply text-sm font-semibold;
}

.Button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Button:hover:not(:disabled) {
  background: var(--surface-three);
}
