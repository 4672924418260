.gradient {
  width: 100%;
  height: 100%;
  background: var(--user-steps-line-full);
}

@media screen and (min-width: 768px) {
  .gradient {
    background: linear-gradient(
      90deg,
      var(--user-steps-line) 0%,
      var(--user-steps-line-full) 13.02%,
      var(--user-steps-line-full) 84.9%,
      var(--user-steps-line) 100%
    );
  }
}
