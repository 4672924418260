.Wrapper {
  background: var(--surface-two);
  border: 1px dashed var(--active-accent);
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
}

.Wrapper:hover {
  border-color: var(--input-border-hover-color);
  background: var(--surface-three);
}

.Wrapper:focus,
.Wrapper:focus:hover {
  border-color: var(--brand-color);
  box-shadow: 0 0 0 3px var(--focus-outline-color);
}

.WrapperActive {
  @apply bg-blue-100;
  border-color: var(--brand-color);
  opacity: 0.75;
}

.WrapperReject,
.WrapperReject:focus {
  border-color: var(--red-color);
  background: var(--red-color--10);
  opacity: 0.75;
}

.Inner {
  padding: 12px;
  display: flex;
  align-items: flex-start;
}

.PhotoWrapper {
  @apply w-14 h-14;

  background: var(--surface-three);
  border-radius: 4px;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.PhotoWrapper img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.PhotoWrapper svg {
  display: block;
}

.PhotoUploading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ContentWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 12px;
}

.MainTextWrapper {
  font-family: var(--font-gotham-pro-medium);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--active-accent);
  display: flex;
  align-items: center;
  margin: 0 16px 8px 0;
}

.MainText {
  @apply ml-1;
}

.SecondText {
  font-family: var(--font-gotham-pro);
  color: var(--text-secondary);
  font-size: 12px;
  line-height: 24px;
  margin: 0 16px 8px 0;
}

.SecondText:last-child {
  margin: 0;
  width: 100%;
}

.RemotePhotoWrapper {
  margin-left: auto;
}
