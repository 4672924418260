.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: var(--text-secondary);
  @apply text-sm font-semibold;
}

.Button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Button:hover:not(:disabled) {
  background: var(--surface-two);
}

.Button[data-size='sm'] {
  @apply w-9 h-9;
}

.Button[data-size='md'] {
  @apply w-10 h-10;
}

.Button[data-size='lg'] {
  @apply w-11 h-11;
}
