.header {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--neutral-lines-two);
  box-sizing: border-box;
  background-color: var(--background);
}

.dropdownOption {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  appearance: none;
  cursor: pointer;
  border: none;
  background: none;
  padding: 12px 16px;
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  color: var(--text-primary);

  &:hover {
    background: var(--surface-three);
  }
}

.optionLogout {
  @apply w-full;
  color: var(--text-primary);
}

.dropdownOptions {
  transition: color 0.15s, background-color 0.15s;
  position: absolute;
  list-style: none;
  padding: 8px 0;
  background-clip: padding-box;
  border-radius: 8px;
  margin: 0;
  background: var(--surface-two);
  color: var(--text-primary);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
}

.userDropdownButtonText {
  color: var(--text-secondary);
  transition: opacity 0.15s ease-in;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-size: 14px;
  letter-spacing: -0.01em;
}

.userDropdownButton:hover .userDropdownButtonText {
  color: var(--text-primary);
}

.menuItem {
  display: flex;
}

.dropdownHeading {
  display: none;
}

@media (max-width: 768px) {
  .dropdownOptions {
    position: fixed !important;
    top: 0 !important;
    bottom: 0;
    right: 0;
    z-index: 999;
    left: auto !important;
    max-width: 95%;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.3);
  }

  .dropdownOption {
    padding: 4px 28px;
  }

  .dropdownHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
    border-bottom: 1px solid var(--background);
    font-weight: 600;
  }

  .avatar {
    margin-left: 12px;
    width: 32px;
    height: 32px;
    background: var(--surface-two) no-repeat center;
    background-size: cover;
    flex-shrink: 0;
    border-radius: 20px;
    border: none;
  }

  .menuItem {
    align-items: center;
    padding: 20px 25px;
    border-radius: 16px;

    svg {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .menuItemActive {
    background-color: var(--surface-default);
    opacity: 1;
  }
}
